import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import Grid from '@mui/material/Grid';

import { CastService, CastSession, CastSong } from "./CastService";
import { ToastService } from "../util/ToastService";
import './CastView.css';

const debugAnimations: boolean = false

export default function CastView() {

    const { sessionId } = useParams();
    CastService.sessionId = sessionId!;

    const [hideChordsChecked, setHideChordsChecked] = React.useState(false);
    const [animationProgress, setAnimationProgress] = React.useState<number>(1)
    const [animateTimer, setAnimateTimer] = React.useState<NodeJS.Timeout | null>(null)
    const [fullscreen, setFullscreen] = React.useState(false);
    const fullscreenRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        CastService.joinAndFetchSession((session: CastSession) => {
            updateSessionDetails(session)
            setupStreamClient();
            ToastService.toastSuccess(`You've joined the Song Cast room.`)
        })
        return () => {
            cleanUpStreamClient();
        };
    }, [sessionId]);

    useEffect(() => {
        function fullscreenchanged(event: any) {
            if (document.fullscreenElement) {
                setFullscreen(true)
            } else {
                setFullscreen(false)
            }
        }
        document.addEventListener("fullscreenchange", fullscreenchanged);
        return () => {
            document.removeEventListener("fullscreenchange", fullscreenchanged);
        }
    }, []);

    function setupStreamClient() {
        console.log('connecting to socket.io stream...')
        CastService.castEventSocket?.disconnect()
        CastService.castEventSocket = null
        if (!sessionId)
            return

        // Socket.io needs URL without the path
        const socket = io('', {
            path: '/socket.io/cast',
        })
        CastService.castEventSocket = socket

        socket.on("connect", () => {
            console.log(`connected to live events stream: ${socket.id}`)

            socket.emit("subscribe_for_session_events", {
                "session_id": sessionId,
            })

            socket.on("subscribe_for_session_events_ack", (data) => {
                console.log('ACK: SongCast subscribed to socket.io room events')
            })
            socket.on("broadcast_new_event", (data) => {
                console.log('socket.io event received')
                refreshSessionDetails()
            })
        })

        socket.on("disconnect", () => {
            console.log('disconnected from live events stream')
        })
    }

    function cleanUpStreamClient() {
        CastService.castEventSocket?.disconnect()
    }

    function refreshSessionDetails() {
        CastService.fetchSession((session: CastSession) => {
            updateSessionDetails(session);
        })
    }

    function updateSessionDetails(newSession: CastSession) {
        CastService.previousSession = CastService.targetSession
        CastService.targetSession = newSession

        CastService.animationProgress = 0
        setAnimationProgress(0)
        animate()
    }

    function animate() {
        if (animateTimer != null) {
            clearTimeout(animateTimer)
        }

        if (CastService.animationProgress < 1) {
            CastService.animationProgress += 0.05
            if (CastService.animationProgress >= 1) {
                CastService.animationProgress = 1
            }
            setAnimationProgress(CastService.animationProgress);

            const animationInterval = debugAnimations ? 150 : 15
            const timer = setTimeout(animate, animationInterval)
            setAnimateTimer(timer)
        }
    }

    let songDetails: any = '';
    const song: CastSong | null | undefined = CastService.targetSession?.song;
    if (song) {
        songDetails = <div>
            <div>Presented Song: {formatSongTitle(song.artist, song.title) }</div>
        </div>;
    }

    function formatLyricsLine(line: string): string {
        if (hideChordsChecked) {
            line = line.replace(/\[.*?\]/g, '');
        }
        return line
    }

    const canvas: any = document.getElementById("canvas");
    const canvasCtx = canvas?.getContext("2d");
    const viewportH = 40
    const lineheight = 4
    const wordWrapLimit = 200

    function extractLyricsLines(session: CastSession | null): string[] {
        if (!session) {
            return []
        }
        let lyricsChunks: string[] = []
        const scrollMode: number = session?.scroll?.mode || 0

        if (scrollMode === 0 || scrollMode === 1) {
            session?.song?.content?.split('\n').forEach((chunk, index) => {
                lyricsChunks.push(formatLyricsLine(chunk));
            });
        } else {
            session?.scroll?.visible_text?.split('\n').forEach((chunk, index) => {
                lyricsChunks.push(formatLyricsLine(chunk));
            });
        }

        let chunksWrapped: string[] = []
        lyricsChunks.forEach((line, index) => {
            const words = line.split(' ');
            let lineWidth = canvasCtx?.measureText(line)?.width;
            if (lineWidth < wordWrapLimit) {
                chunksWrapped.push(line);
            } else {
                let lineWrapped = '';
                words.forEach((word, index) => {
                    let lineWrappedNew = lineWrapped + word + ' ';
                    let lineWidthNew = canvasCtx?.measureText(lineWrappedNew)?.width;
                    if (lineWidthNew < wordWrapLimit) {
                        lineWrapped = lineWrappedNew;
                    } else {
                        chunksWrapped.push(lineWrapped);
                        lineWrapped = word + ' ';
                    }
                });
                chunksWrapped.push(lineWrapped);
            }
        });

        return chunksWrapped
    }

    let frontLyricsChunks: string[] = extractLyricsLines(CastService.targetSession)
    let previousLyricsChunks: string[] = extractLyricsLines(CastService.previousSession)

    function getFadeDirection(): number {
        const frontStart: number | undefined = CastService.targetSession?.scroll?.view_start
        const previousStart: number | undefined = CastService.previousSession?.scroll?.view_start
        if (frontStart == null || previousStart == null) {
            return 0
        }
        return frontStart - previousStart
    }
    const fadeDirection = getFadeDirection()
    const scrollMode: number = CastService.targetSession?.scroll?.mode || 0

    function getYOffset(scrollMode: number, linesCount: number, fadeOffset: number): number {
        if (scrollMode === 0) {
            return lineheight;
        } else if (scrollMode === 1) {
            const scroll = CastService.targetSession?.scroll?.view_start || 0
            return viewportH / 2 -scroll * lineheight + lineheight
        } else {
            return viewportH / 2 - linesCount * lineheight / 2 + fadeOffset + lineheight
        }
    }

    function highlightChunk(chunk: string): any[] {
        const chunkParts = chunk.split(/(\[.*?\])/g)
        return chunkParts.map((part, index) => {
            if (part.startsWith('[') && part.endsWith(']')) { // chords
                return <tspan key={index} font-weight="bold" fill="red">{part}</tspan>
            } else {
                return <tspan key={index}>{part}</tspan>
            }
        })
    }

    let frontLyricsTexts: any[] = []
    { // previous (current) slide fading out
        const linesCount = previousLyricsChunks.length
        const fadeOffset = -animationProgress * fadeDirection * lineheight
        const yOffset = getYOffset(scrollMode, linesCount, fadeOffset)
        const alpha = 1.0 - animationProgress
        previousLyricsChunks.forEach((chunk, index) => {
            const y = yOffset + index * lineheight
            const chunkElements = highlightChunk(chunk)
            const element = <text key={index}
                x="50" y={y} fontSize={lineheight}
                textAnchor="middle" alignmentBaseline="auto" fill="white"
                opacity={alpha}
                >{ chunkElements }</text>
            frontLyricsTexts.push(element);
        });
    }
    { // target slide fading in
        const linesCount = frontLyricsChunks.length
        const fadeOffset = (1.0 - animationProgress) * fadeDirection * lineheight
        const yOffset = getYOffset(scrollMode, linesCount, fadeOffset)
        const alpha = animationProgress
        frontLyricsChunks.forEach((chunk, index) => {
            const y = yOffset + index * lineheight
            const chunkElements = highlightChunk(chunk)
            const element = <text key={index + previousLyricsChunks.length}
                x="50" y={y} fontSize={lineheight}
                textAnchor="middle" alignmentBaseline="auto" fill="white"
                opacity={alpha}
                >{ chunkElements }</text>
            frontLyricsTexts.push(element);
        });
    }

    function toggleFullscreen() {
        if (!fullscreen) {
            setFullscreen(true)
            fullscreenRef.current?.requestFullscreen()
        } else {
            setFullscreen(false)
            document.exitFullscreen()
        }
    }

    const svgClass = fullscreen ? "song-lyrics-svg-fullscreen" : "song-lyrics-svg"

    return (
        <div className="mt-3">
            <Container maxWidth="lg" disableGutters>
                <div className="ml-2">
                    <h2>Song Cast</h2>
                </div>

                <div>
                    Room code: <b>{ sessionId }</b>
                </div>
                {songDetails}

                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <FormControlLabel control={
                            <Checkbox
                                checked={hideChordsChecked}
                                onChange={(event) => setHideChordsChecked(event.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                                />
                        } label="Hide chords" />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={toggleFullscreen} title="Enter fullscreen" aria-label="Enter fullscreen" size="large">
                            <FullscreenIcon fontSize="large"/>
                        </IconButton>
                    </Grid>
                </Grid>

                <canvas id="canvas" style={{display: 'none'}}></canvas>
                <div ref={fullscreenRef}>
                    <Card>
                        <svg className={svgClass} viewBox="0 0 100 40">
                            {frontLyricsTexts}
                        </svg>
                    </Card>
                </div>
            </Container>
        </div>
    );
}

function formatSongTitle(artist: string | null, title: string): string {
    if (artist) {
        return `${artist} - ${title}`;
    } else {
        return title;
    }
}