import React from "react";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ToastService } from "../util/ToastService";
import { CallbackHell } from "./CallbackHell";
import { EditorSongCreate, SessionService } from "./SessionService";
import { defaultChordNotationId } from "./ChordNotationSelect";


export default function CreateSongDialog(props: any) {

    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('');
    const [artist, setArtist] = React.useState('');
    const [chordsNotationId, setChordsNotationId] = React.useState(0);

    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const onSuccessfulCreate = (songId: string) => {
        navigate(`/ui/editor/session/${SessionService.sessionId}/song/${songId}`);
    }

    const confirmSaving = () => {
        if (title.length === 0) {
            ToastService.toastError('Title is required')
            return
        }

        const payload: EditorSongCreate = {
            title: title,
            artist: artist,
            content: '',
            chords_notation_id: defaultChordNotationId,
        }
        SessionService.createEditorSong(payload, onSuccessfulCreate)
        handleClose()
    };

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            confirmSaving();
        }
    }

    CallbackHell.createSongClickOpen = () => {
        setOpen(true);
        setTitle('');
        setArtist('');
        setChordsNotationId(0);
    };

    return (
        <div>
            <Dialog 
                fullWidth
                open={open} onClose={handleClose}>
                <DialogTitle>New Song</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="inputTitle"
                        label="Title"
                        fullWidth
                        variant="outlined"
                        required
                        value={title} onChange={(e: any) => {setTitle(e.target.value)}}
                        onKeyDown={handleKeyDown} 
                    />

                    <TextField
                        margin="normal"
                        id="inputArtist"
                        label="Artist"
                        fullWidth
                        variant="outlined"
                        value={artist} onChange={(e: any) => {setArtist(e.target.value)}}
                        onKeyDown={handleKeyDown} 
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmSaving} type="submit">Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}