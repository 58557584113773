import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


export function PageNotFound() {
    return (
        <div>
            <Container maxWidth="lg" disableGutters>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh"
                    >
                    <h2>Page not found</h2>
                </Box>
            </Container>
        </div>
    );
}
