import axios from "axios";
import { ToastService } from "../util/ToastService";
import {v4 as uuidv4} from 'uuid';
import { Socket } from "socket.io-client";


export interface CastSession {
    short_id: string;
    create_timestamp: number;
    update_timestamp: number;
    ttl: number;
    members: CastMember[];
    song: CastSong | null;
    scroll: CastScroll | null;
    chat_messages: CastChatMessage[];
}

export interface CastMember {
    public_member_id: string;
    name: string;
    type: string;
}

export interface CastSong {
    id: string;
    chosen_by: string;
    title: string;
    artist: string | null;
    content: string;
    chords_notation_id: number;
}

export interface CastScroll {
    view_start: number;
    view_end: number;
    visible_text: string | null;
    // Cast Scroll Control mode: 0 = None, 1 = share scroll, 2 = slides 1, 3 = slides 2, 4 = slides 4, 5 = slides 8
    mode: number | null;
}

export interface CastChatMessage {
    timestamp: number;
    author: string;
    text: string;
}

export interface CastSessionJoin {
    member_name: string;
}

export interface CastSessionJoined {
    short_id: string;
    public_member_id: string;
    member_name: string;
    rejoined: boolean;
}

export class CastService {
    static sessionId: string = '';
    static session: CastSession | null = null;
    static myDeviceId: string = '';
    static myPublicMemberId: string = '';
    static myMemberName: string = 'Web client';
    static castEventSocket: Socket | null = null    
    static animationProgress: number = 1   
    
    static targetSession: CastSession | null = null;
    static previousSession: CastSession | null = null;

    static joinAndFetchSession(onFetched: (session: CastSession) => void) {
        this.joinSession(() => {
            this.fetchSession(onFetched)
        })
    }

    static joinSession(onJoined: () => void) {
        const deviceId = this.getMyDeviceId()

        axios.post(`/api/cast/${this.sessionId}/join`, {
            member_name: this.myMemberName,
        }, {
            headers: {
                'X-Songbook-Device-Id': deviceId,
            }
        }).then(response => {
            try {

                const sessionJoined = response.data as CastSessionJoined
                this.myPublicMemberId = sessionJoined.public_member_id
                this.myMemberName = sessionJoined.member_name
                onJoined()

            } catch(e: unknown) {
                console.error(e);
                ToastService.toastError(`Fetching data: ${e}`)
            }
        }).catch(err => {
            if (err.response !== undefined) {
                if (err.response.status === 404) {
                    ToastService.toastError(`Song Cast session not found. Room code is invalid or it has expired.`);
                    return
                }
            }
            ToastService.showAxiosError(`Fetching session data`, err)
        });
    }

    static fetchSession(onFetched: (session: CastSession) => void) {

        const onFetchSession = (session: CastSession) => {
            this.session = session;
            onFetched(session)
        }
        const deviceId = this.getMyDeviceId()

        axios.get(`/api/cast/${this.sessionId}`, {
            headers: {
                'X-Songbook-Device-Id': deviceId,
            }
        }).then(response => {
            try {

                const session = response.data as CastSession
                onFetchSession(session)

            } catch(e: unknown) {
                console.error(e);
                ToastService.toastError(`Fetching data: ${e}`)
            }
        }).catch(err => {
            if (err.response !== undefined) {
                if (err.response.status === 404) {
                    ToastService.toastError(`Song Cast session not found. Room code is invalid or it has expired.`);
                    return
                }
            }
            ToastService.showAxiosError(`Fetching session data`, err)
        });
    }

    static getMyDeviceId(): string {
        if (this.myDeviceId === '') {
            this.myDeviceId = localStorage.getItem('songcast.device_id') || '';
            if (this.myDeviceId === '') {
                this.myDeviceId = generateUUID();
                console.log('Generated new device ID')
                localStorage.setItem('songcast.device_id', this.myDeviceId);
            }
        }
        return this.myDeviceId
    }

}

function generateUUID(): string {
    let myuuid = uuidv4();
    return myuuid.replace(/-/g, '');
}
