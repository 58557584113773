import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import { Grid, IconButton, Link, Menu, MenuItem, TextField, Tooltip } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SyncIcon from '@mui/icons-material/Sync';

import { EditorSong, EditorSongUpdate, SessionService } from "./SessionService";
import ChordNotationSelect from "./ChordNotationSelect";
import { ToastService } from "../util/ToastService";
import ConfirmDialog, { ConfirmDialogHandle } from "../util/ConfirmDialog";
import ChoiceDialog, { ChoiceDialogHandle, ChoiceDialogOptions } from "../util/ChoiceDialog";


function SongView() {

    const { sessionId, songId } = useParams();
    SessionService.sessionId = sessionId!;

    const [title, setTitle] = React.useState('');
    const [artist, setArtist] = React.useState('');
    const [content, setContent] = React.useState('');
    const [chordsNotationId, setChordsNotationId] = React.useState(0);

    const [savedTitle, setSavedTitle] = React.useState('');
    const [savedArtist, setSavedArtist] = React.useState('');
    const [savedContent, setSavedContent] = React.useState('');
    const [savedChordsNotationId, setSavedChordsNotationId] = React.useState(0);

    const onEditorSongFetched = (song: EditorSong) => {
        setTitle(song.title);
        setArtist(song.artist || "");
        setContent(song.content);
        setChordsNotationId(song.chords_notation_id);

        setSavedTitle(song.title);
        setSavedArtist(song.artist || "");
        setSavedContent(song.content);
        setSavedChordsNotationId(song.chords_notation_id);
    }

    useEffect(() => {
        SessionService.readEditorSong(songId!, onEditorSongFetched);
    }, [songId]);

    const navigate = useNavigate();
    const goToSessionPage = () => {
        if (hasUnsavedChanges()) {
            (choiceDialogRef.current! as ChoiceDialogHandle).open({
                title: 'Unsaved Changes',
                message: 'Do you really want to leave? You have unsaved changes.',
                positiveOption: 'Save',
                negativeOption: 'Discard Changes',
                neutralOption: 'Cancel',
                onPositive: () => {
                    onSaveClick();
                },
                onNegative: () => {
                    navigate(`/ui/editor/session/${sessionId}`)
                },
                onNeutral: () => {},
            } as ChoiceDialogOptions);
            return
        }
        navigate(`/ui/editor/session/${sessionId}`)
    }

    const onSaveClick = () => {
        if (title.length === 0) {
            ToastService.toastError('Title is required')
            return
        }
        if (chordsNotationId === 0) {
            ToastService.toastError('Chords Notation is required')
            return
        }

        setSavedTitle(title);
        setSavedArtist(artist);
        setSavedContent(content);
        setSavedChordsNotationId(chordsNotationId);

        const payload: EditorSongUpdate = {
            title: title,
            artist: artist,
            content: content,
            chords_notation_id: chordsNotationId,
        }
        SessionService.updateEditorSong(songId!, payload);
    }
    const onSaveClickRef = useRef();
    onSaveClickRef.current = onSaveClick as unknown as undefined;

    function hasUnsavedChanges(): boolean {
        return title !== savedTitle || artist !== savedArtist || content !== savedContent || chordsNotationId !== savedChordsNotationId;
    }
    const hasUnsavedChangesRef = useRef();
    hasUnsavedChangesRef.current = hasUnsavedChanges as unknown as undefined;

    // prevent leaving the page
    useEffect(() => {
        const handleBeforeUnload = (event: any) => {
            const hasUnsavedChangesCallback = hasUnsavedChangesRef.current as unknown as () => boolean;
            if (hasUnsavedChangesCallback()) {
                event.preventDefault()
                event.returnValue = "Unsaved Changes"
                console.log('unsaved changes detected, preventing from leaving the page');
                return "Unsaved Changes"
            } else {
                return undefined
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => window.removeEventListener('beforeunload', handleBeforeUnload)
    }, [hasUnsavedChangesRef])

    // save on Ctrl+S
    useEffect(() => {
        const handleKeyDown = (event: any) => {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if((event.ctrlKey || event.metaKey) && charCode === 's') {
                const onSaveClickCallback = onSaveClickRef.current as unknown as () => void;
                onSaveClickCallback();
                event.preventDefault();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener("keydown", handleKeyDown)
    }, [onSaveClickRef])


    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenu = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const onDeleteSongClick = () => {
        (confirmDialogRef.current! as ConfirmDialogHandle).open(`Do you really want to delete this song "${title}"?
        You won't be able to revert it
        and after synchronizing the changes, it will be gone on your local device as well.`, () => {
            SessionService.deleteEditorSong(songId!, () => {
                goToSessionPage();
            });
        });
    }

    const onRefreshClick = () => {
        if (hasUnsavedChanges()) {
            (choiceDialogRef.current! as ChoiceDialogHandle).open({
                title: 'Unsaved Changes',
                message: 'Do you really want to reload the song? Your unsaved changes will be dropped.',
                positiveOption: 'Discard Changes',
                negativeOption: 'Cancel',
                onPositive: () => {
                    refreshSong();
                },
                onNegative: () => {},
                onNeutral: () => {},
            } as ChoiceDialogOptions);
            return
        }
        refreshSong();
    }

    function refreshSong() {
        SessionService.readEditorSong(songId!, (song: EditorSong) => {
            onEditorSongFetched(song);
            ToastService.toastSuccess('Song reloaded');
        });
    }

    const openChordFormatSpecification = () => {
        const url = 'https://igrek51.github.io/android-songbook/chord-format/';
        window.open(url, '_blank')?.focus();
    }

    const confirmDialogRef = useRef(null);
    const choiceDialogRef = useRef(null);

    return (
        <div className="mt-3">
            <Container maxWidth="lg" disableGutters>

                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    >
                    <Grid item>
                        <div className="d-inline-block align-top">
                            <IconButton onClick={goToSessionPage} title="Back to the session page" aria-label="Back to the session page" size="medium">
                                <ArrowBackIcon fontSize="medium"/>
                            </IconButton>
                        </div>
                        <div className="d-inline-block">
                            <h2>Songbook: Web Editor</h2>
                        </div>
                    </Grid>
                    <Grid item>
                        <div className="d-inline-block ml-2 mt-2">
                            <IconButton
                                aria-label="more"
                                id="basic-menu-button"
                                aria-controls={openMenu ? 'basic-menu' : undefined}
                                aria-expanded={openMenu ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                                >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'basic-menu-button',
                                }}
                                anchorEl={menuAnchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={openMenu}
                                onClose={handleMenuClose}
                                >
                                <MenuItem onClick={() => {handleMenuClose(); openChordFormatSpecification();}}>Help: Chord Format Specification</MenuItem>
                            </Menu>
                        </div>
                    </Grid>
                </Grid>

                <ConfirmDialog ref={confirmDialogRef} />
                <ChoiceDialog ref={choiceDialogRef} />

                <div className="mb-2">
                    <div className="d-inline-block mx-1 mt-2">
                        <ButtonGroup>
                            <Button variant="contained" color="success" onClick={onSaveClick}>
                                <SaveIcon fontSize="small" /> Save
                            </Button>
                            <Button variant="contained" color="primary" onClick={onRefreshClick}>
                                <SyncIcon fontSize="small" /> Refresh
                            </Button>
                            <Button variant="contained" color="error" onClick={onDeleteSongClick}>
                                <DeleteIcon fontSize="small" /> Delete
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>

                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                        <div className="mt-1">
                            <TextField 
                                label="Song Title"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                required
                                value={title} onChange={(e: any) => { setTitle(e.target.value) }}
                                />
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <div className="mt-1">
                            <TextField 
                                label="Artist"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                value={artist} onChange={(e: any) => { setArtist(e.target.value) }}
                                />
                        </div>
                    </Grid>
                </Grid>
                
                <div className="mt-3">
                    <Grid 
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}>
                        <Grid item xs>
                            <ChordNotationSelect
                                chordsNotationIdValue={chordsNotationId}
                                onChange={setChordsNotationId}
                                />
                        </Grid>
                        <Grid item xs="auto">
                            <Tooltip title="Help: Chord notations in a Manual">
                                <Link href="https://igrek51.github.io/android-songbook/chord-notations/" target="_blank" rel="noopener noreferrer">
                                    <IconButton aria-label="Help: Chord notations in a Manual">
                                        <HelpOutlineIcon fontSize="small" />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </div>

                <div className="mt-2">
                    <TextField
                        label="Lyrics with chords"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        multiline
                        minRows={20}
                        value={content}
                        onChange={(e: any) => { setContent(e.target.value) }}
                        inputProps={{style: {
                            fontFamily: '"Roboto Mono", monospace, monospace',
                        }}}
                        />
                </div>

            </Container>
        </div>
    );
}

export default SongView;
