import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import SessionView from "./session/SessionView";
import CastView from "./cast/CastView";
import SongView from "./session/SongView";
import HomeView from "./home/HomeView";
import { PageNotFound } from "./PageNotFound";


function App() {
  return (
    <div>
      <ToastContainer />
      <BrowserRouter basename="/">
        <Routes>
            <Route path="/" element={<Navigate to='/ui' />}/>
            <Route path="/ui" element={<HomeView/>}/>
            <Route path="/ui/editor/session/:sessionId" element={<SessionView/>}/>
            <Route path="/ui/editor/session/:sessionId/song/:songId" element={<SongView/>}/>
            <Route path="/ui/cast/:sessionId/spectate" element={<CastView/>}/>
            <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
