import React from "react";
import axios from "axios";
import { ToastService } from "../util/ToastService";
import { CallbackHell } from "./CallbackHell";


export interface EditorSession {
    id: string;
    create_timestamp: number;
    update_timestamp: number;
    current_hash: string;
    songs: EditorSong[];
}

export interface EditorSong {
    id: string;
    update_timestamp: number;
    title: string;
    artist: string | null;
    content: string;
    chords_notation_id: number;
}

export interface EditorSongCreate {
    title: string;
    artist: string | null;
    content: string;
    chords_notation_id: number;
}

export interface EditorSongUpdate {
    title: string | null;
    artist: string | null;
    content: string | null;
    chords_notation_id: number | null;
}

export class SessionService {
    static sessionId: string = '';
    static session: EditorSession;

    static fetchSession(onEditorSessionFetched: (session: EditorSession) => void) {

        const onFetchSession = (session: EditorSession) => {
            this.session = session;
            onEditorSessionFetched(session)
        }

        axios.get(`/api/editor/session/${this.sessionId}`)
            .then(response => {
                try {

                    const session = response.data as EditorSession
                    onFetchSession(session)

                } catch(e: unknown) {
                    console.error(e);
                    ToastService.toastError(`Fetching data: ${e}`)
                }
            }).catch(err => {
                if (err.response !== undefined) {
                    if (err.response.status === 404) {
                        ToastService.toastError(`Session not found. Most likely it has expired. Synchronize again from your local device.`);
                        return
                    }
                }
                ToastService.showAxiosError(`Fetching session data`, err)
            });
    }

    static readEditorSong(songId: string, onFetch: (song: EditorSong) => void) {
        axios.get(`/api/editor/session/${this.sessionId}/song/${songId}`,
            ).then(response => {

                const responseObject = response.data as EditorSong
                onFetch(responseObject)

            }).catch(err => {
                ToastService.showAxiosError(`Fetching the song`, err)
            });
    }

    static createEditorSong(payload: EditorSongCreate, onSuccessfulCreate: (songId: string) => void) {
        axios.post(`/api/editor/session/${this.sessionId}/song`,
            payload,
            ).then(response => {

                const responseObject = response.data as EditorSong
                ToastService.toastSuccess(`Song has been created`);
                onSuccessfulCreate(responseObject.id)

            }).catch(err => {
                ToastService.showAxiosError(`Creating a song`, err)
            });
    }

    static updateEditorSong(songId: string, payload: EditorSongUpdate) {
        axios.put(`/api/editor/session/${this.sessionId}/song/${songId}`, 
            payload,
            ).then(response => {

                ToastService.toastSuccess(`Song saved`);

            }).catch(err => {
                ToastService.showAxiosError(`Saving song`, err)
            });
    }

    static deleteEditorSong(songId: string, onSuccess: () => void) {
        axios.delete(`/api/editor/session/${this.sessionId}/song/${songId}`,
            ).then(response => {

                ToastService.toastSuccess(`Song has been deleted`);
                onSuccess()

            }).catch(err => {
                ToastService.showAxiosError(`Deleting song`, err)
            });
    }

}