import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CallbackHell } from "../session/CallbackHell";


export type ChoiceDialogHandle = {
    open: (options: ChoiceDialogOptions) => void;
};
  
export type ChoiceDialogOptions = {
    title: string;
    message: string;
    positiveOption: string;
    negativeOption: string;
    neutralOption: string;
    onPositive: () => void;
    onNegative: () => void;
    onNeutral: () => void;
};
type Props = {};

const ChoiceDialog = forwardRef<ChoiceDialogHandle, Props>((props, ref) => {

    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [positiveOption, setPositiveOption] = React.useState("");
    const [negativeOption, setNegativeOption] = React.useState("");
    const [neutralOption, setNeutralOption] = React.useState("");

    useImperativeHandle(ref, () => ({
        open(options: ChoiceDialogOptions) {
            CallbackHell.confirmDialogPositiveCallback = options.onPositive
            CallbackHell.confirmDialogNegativeCallback = options.onNegative
            CallbackHell.confirmDialogNeutralCallback = options.onNeutral
            setTitle(options.title);
            setMessage(options.message);
            setPositiveOption(options.positiveOption);
            setNegativeOption(options.negativeOption);
            setNeutralOption(options.neutralOption || "");
            setOpen(true);
        },
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const handlePositiveButton = () => {
        setOpen(false);
        CallbackHell.confirmDialogPositiveCallback();
    };

    const handleNegativeButton = () => {
        setOpen(false);
        CallbackHell.confirmDialogNegativeCallback();
    };

    const handleNeutralButton = () => {
        setOpen(false);
        CallbackHell.confirmDialogNeutralCallback();
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-3choices-title"
                aria-describedby="alert-dialog-3choices-description"
            >
            <DialogTitle id="alert-dialog-3choices-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-3choices-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleNeutralButton}>
                    {neutralOption}
                </Button>
                <Button onClick={handleNegativeButton} autoFocus>
                    {negativeOption}
                </Button>
                <Button onClick={handlePositiveButton} autoFocus>
                    {positiveOption}
                </Button>
            </DialogActions>
            </Dialog>

        </div>
    );
});

export default ChoiceDialog;
