import React from 'react';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { ToastService } from '../util/ToastService';
import { HistoryService, SessionHistoryItem } from './HistoryService';
import { Link } from 'react-router-dom';


export class EditorHistoryComponent extends React.Component {

    deleteHistoryItem(path_id: string) {
        HistoryService.deleteHistoryItem(path_id)
        ToastService.toastSuccess('History item deleted')
        this.forceUpdate()
    }

    render() {
        const historyItems: SessionHistoryItem[] = HistoryService.readOrderedItems();

        if (historyItems.length === 0) {
            return (
                <div></div>
            )
        }

        return (
            <div className="mt-4">
                Your recent sessions:
                <ul>
                    {historyItems.map(item =>
                    <li key={item.path_id}>
                        <Link to={`/ui/editor/session/${item.path_id}`}>{item.title}</Link>

                        <div className="d-inline-block ml-2">
                            <IconButton onClick={() => {this.deleteHistoryItem(item.path_id)} } title="Delete" aria-label="Delete" size="small">
                                <ClearIcon fontSize="small"/>
                            </IconButton>
                        </div>
                    </li>
                    )}
                </ul>
            </div>
        );
    }
}
