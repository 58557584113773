import React, { useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import { EditorSession, EditorSong, SessionService } from "./SessionService";
import CreateSongDialog from "./CreateSongDialog";
import { CallbackHell } from "./CallbackHell";
import { HistoryService } from "./HistoryService";


export default function SessionView() {

    const { sessionId } = useParams();
    SessionService.sessionId = sessionId!;

    const [songItems, setSongItems] = React.useState<EditorSong[]>([]);

    const clickNewSong = () => {
        CallbackHell.createSongClickOpen()
    };

    useEffect(() => {
        const onEditorSessionFetched = (session: EditorSession) => {
            setSongItems(session.songs);
            HistoryService.saveHistoryItem(sessionId!, sessionId!);
        }
        SessionService.fetchSession(onEditorSessionFetched)
    }, [sessionId]);

    const navigate = useNavigate();
    const clickNavigateToSong = (songId: string) => {
        navigate(`/ui/editor/session/${sessionId}/song/${songId}`);
    }

    return (
        <div className="mt-3">
            <Container maxWidth="lg" disableGutters>

                <div className="ml-2">
                    <h2>Songbook: Web Editor</h2>
                </div>

                <div className="ml-2">
                    Synchronize your songs from your local device to edit them in the Web Editor.
                    The synchronization session will be active for 24 hours. After that, the songs will be deleted.
                </div>

                <div className="mt-3 ml-2">
                    <Button variant="contained" color="primary" onClick={clickNewSong}>
                        <AddIcon fontSize="small" /> New Song
                    </Button>
                    <CreateSongDialog/>
                </div>

                <div className="mt-1">
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Synchronized Songs
                            </ListSubheader>
                        }
                        >
                        {songItems.map(song =>
                        <ListItemButton
                            onClick={e => { clickNavigateToSong(song.id) }}
                            dense
                            key={song.id}
                            >
                            <ListItemIcon>
                                <MusicNoteIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={song.title}
                                secondary={song.artist || ''}
                                />
                        </ListItemButton>
                        )}
                    </List>
                </div>

            </Container>
        </div>
    );
}

function displaySongName(song: EditorSong): string {
    if (song.artist) {
        return `${song.title} - ${song.artist}`;
    }
    return song.title;
}
