import React from "react";
import Container from '@mui/material/Container';
import { EditorHistoryComponent } from '../session/EditorHistoryComponent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { ToastService } from "../util/ToastService";


export default function HomeView() {
    const navigate = useNavigate();
    const [castRoomCode, setcastRoomCode] = React.useState('');

    function joinCastRoom(roomCode: string) {
        return () => {
            if (roomCode.length !== 6) {
                ToastService.toastError(`Enter 6-digit room code`)
                return
            }
            navigate(`/ui/cast/${roomCode}/spectate`);
        }
    }

    return (
        <div className="mt-3">
            <Container maxWidth="lg" disableGutters>

                <h2 className="ml-2">Songbook Web Services</h2>
                <div>
                    This is the web interface of the Songbook app. It serves functionality that requires server-side processing.
                    Some features are already available, whereas others are still forthcoming.
                </div>

                <h4 className="ml-2 mt-3">1. Web Editor &amp; Synchronize Sessions</h4>
                <div>
                    You can Synchronize your songs with a Web Editor to modify them in any browser, for instance, on your desktop computer.
                    Then synchronize them back to see the changes on your local device.
                    Click 3-dots icon on "My Songs" and click "Synchronize with Web Editor".
                    This will start a temporary session for 24 hours, which you can access by opening the link on any device.
                    <br/>
                    Keep in mind that if changes were made in both places (locally and remotely), there might be a conflict to be resolved.
                    <br/>
                    First, start a new Synchronization Session in the Songbook app on your device and open the provided link.
                </div>
                <EditorHistoryComponent/>

                <h4 className="ml-2 mt-3">2. Song Cast</h4>
                <div>
                    <b>Song Cast</b> is a <i>Songbook</i>'s protocol to display songs on many devices over Internet.
                    Create a room in the Songbook app so that your friends could join it.
                    Once the presenter chooses a song, it will be broadcasted and displayed on all devices connected to a room.
                    <br/>
                    Enter a 6-digit room code to join the room as spectator:
                    <div>
                    <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    >
                        <Grid item>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="roomCode"
                                label="Room Code"
                                variant="outlined"
                                size="small"
                                required
                                value={castRoomCode} onChange={(e: any) => {setcastRoomCode(e.target.value)}}
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" size="large" onClick={joinCastRoom(castRoomCode)}>
                                <AddIcon fontSize="small" /> Join
                            </Button>
                        </Grid>
                    </Grid>
                    </div>
                </div>

                <h4 className="ml-2 mt-3">Links</h4>
                <div>
                    <ul>
                        <li>
                            <a href="https://igrek51.github.io/android-songbook" target="_blank" rel="noopener noreferrer">Songbook Manual</a>
                        </li>
                        <li>
                            <a href="https://github.com/igrek51/android-songbook" target="_blank" rel="noopener noreferrer">Songbook Project on GitHub</a>
                        </li>
                        <li>
                            Songbook is available on <a href="https://play.google.com/store/apps/details?id=igrek.songbook" target="_blank" rel="noopener noreferrer">Google Play</a> for free.
                            Please give it ⭐️⭐️⭐️⭐️⭐️ stars.
                            It is available on <b>phones</b>, <b>tablets</b> and <b>TV</b> devices with <b>Android</b>.
                            <br/>
                            <a href='https://play.google.com/store/apps/details?id=igrek.songbook&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
                                <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' width={200}/>
                            </a>
                        </li>
                    </ul>
                </div>

            </Container>
        </div>
    );
}
