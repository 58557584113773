import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


export default function ChordNotationSelect(props: any) {

    const idValue: number = props.chordsNotationIdValue;

    const onIdValueChange = props.onChange;

    const handlechordsNotationChange = (event: SelectChangeEvent) => {
        onIdValueChange(parseInt(event.target.value));
    };

    const chordNotationNames: Record<string, string | undefined> = {
        '3': "English",
        '1': "German (#/b)",
        '2': "German (is/es)",
        '5': "Dutch",
        '6': "Japanese",
        '4': "Solfege",
    };
    const chordNotationShortNames: Record<string, string | undefined> = {
        '3': " Am Bb B C C# Dm Eb",
        '1': " a B H C C# d Eb",
        '2': " a B H C Cis d Es",
        '5': " Am Bes B C Cis Dm Es",
        '6': " Im Hen-ro Ro Ha Ei-ha Nim Hen-ho",
        '4': " Lam Sib Si Do Do# Rem Mib",
    };
    const chordNotationTypesKeys: string[] = [
        '3',
        '1',
        '2',
        '5',
        '6',
        '4',
    ];

    const menuItems = chordNotationTypesKeys.map((key) =>
        <MenuItem value={key} key={key}>
            {chordNotationNames[key]}: 
            {chordNotationShortNames[key]}
        </MenuItem>
    );

    function stringValue(num: number): string | undefined {
        if (num === 0) {
            return "";
        }
        return num.toString();
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="select-chords-notation">Chords notation *</InputLabel>
            <Select
                labelId="select-chords-notation"
                id="demo-simple-select"
                value={stringValue(idValue)}
                label="Chords notation *"
                onChange={handlechordsNotationChange}
                required
                >
                {menuItems}
            </Select>
        </FormControl>
    );
}

export const defaultChordNotationId: number = 3;
