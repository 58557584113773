import React, { forwardRef, useCallback, useImperativeHandle, useRef } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CallbackHell } from "../session/CallbackHell";


export type ConfirmDialogHandle = {
    open: (msg: string, onConfirm: () => void) => void;
};

type Props = {};

const ConfirmDialog = forwardRef<ConfirmDialogHandle, Props>((props, ref) => {

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    useImperativeHandle(ref, () => ({
        open(msg: string, onConfirm: () => void) {
            CallbackHell.confirmDialogPositiveCallback = onConfirm
            setMessage(msg);
            setOpen(true);
        },
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        CallbackHell.confirmDialogPositiveCallback();
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
                {"Are you sure?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Confirm
                </Button>
            </DialogActions>
            </Dialog>

        </div>
    );
});

export default ConfirmDialog;
